<template>
    <div>
        <comp-table title="确认转发统计" :columns="columns" table-api="/gateway/chat/app/chat/group/selectUserAfficheReadView?reader=false"></comp-table>
        <CompModal title="转发统计" ref="modal" width="400px"></CompModal>
    </div>
</template>

<script>
import CompTable from "../../jointly/components/comp-table.vue"
import CompModal from "../../residentdatabase/components/CompModal.vue"

export default {
    components: { CompTable, CompModal },

    data() {
        return {
            columns: [
                {
                    title: "公告内容",
                    key: "content",
                    minWidth: 300,
                },
                {
                    title: "已转",
                    key: "count",
                    minWidth: 150,
                    render: (h, params) => {
                        return h(
                            "p",
                            {
                                style: {
                                    cursor: "pointer",
                                    color: "#4bcc00",
                                    textDecoration: "underline",
                                },
                                on: {
                                    click: () => {
                                        this.$refs.modal.iframe(`${window.vue.getProxy()["/custom"].target}/#/pages/im/group/groupnotice?groupCode=${params.row.groupId}&noticeId=${params.row.afficheId}&type=transmit&currentIndex=1&token=${window.sessionStorage.getItem("accessToken")}`)
                                    },
                                },
                            },
                            (params.row.count || 0) - (params.row.unCount || 0)
                        )
                    },
                },
                {
                    title: "未转",
                    key: "unCount",
                    minWidth: 150,
                    render: (h, params) => {
                        return h(
                            "p",
                            {
                                style: {
                                    cursor: "pointer",
                                    color: "#4bcc00",
                                    textDecoration: "underline",
                                },
                                on: {
                                    click: () => {
                                        this.$refs.modal.iframe(`${window.vue.getProxy()["/custom"].target}/#/pages/im/group/groupnotice?groupCode=${params.row.groupId}&noticeId=${params.row.afficheId}&type=transmit&token=${window.sessionStorage.getItem("accessToken")}`)
                                    },
                                },
                            },
                            params.row.unCount || 0
                        )
                    },
                },
            ],
        }
    },
}
</script>
